import { ParsedUrlQuery } from "querystring";

import { Component, createContext } from "react";
import { flushSync } from "react-dom";
import { withRouter, NextRouter } from "next/router";
import { Routes, getPreferred } from "src/components/shared/Router";
import { Logger } from "src/common/logger";
import {
  BankAuthorisationResource,
  BillingRequestResource,
  BillingRequestFlowResource,
  InstitutionResource,
  PlanResource,
  PaylinkResource,
  MandateMigrationResource,
  InstalmentTemplateResource,
  CustomerEmailVerificationCreateResponseBody,
} from "@gocardless/api/dashboard/types";
import { CountryCodes } from "src/common/country";
import { CustomerFieldsConfig } from "src/config/customer-details/customer-details-config";
import { HTTPError } from "@gocardless/api/utils/api";
import { Locale } from "src/common/i18n";

import { ErrorTypeEnum, PlaidErrorsEnum } from "./errors";
import { RuntimeMode } from "./RuntimeModeInitialiser";

export interface ResidenceCountryMetaType {
  countryCode?: CountryCodes;
  customerFieldsConfig?: CustomerFieldsConfig;
}

export interface ErrorType {
  errorType: ErrorTypeEnum | PlaidErrorsEnum;
  errorMessage?: string;
  httpError?: HTTPError;
}

export interface BankAuthorisationErrorType extends ErrorType {
  failureReason: string;
}

export interface PayerThemeType {
  button_background_colour: string;
  link_text_colour: string;
  header_background_colour: string;
}

export interface ReturningCustomerPersonalDetails {
  email: string;
  customer_id: string;
  family_name: string;
  given_name: string;
}

export interface GlobalStateType {
  availableInstitutions?: InstitutionResource[];
  bankAuthorisation?: BankAuthorisationResource;
  billingRequest?: BillingRequestResource;
  billingRequestFlow?: BillingRequestFlowResource;
  billingRequestId?: string;
  error?: ErrorType;
  showEmailVerification?: boolean;
  selectedInstitution?: InstitutionResource;
  selectedBankAccountCountryCode?: CountryCodes;
  bankDetailsConfirmed?: boolean;
  isEditClicked: boolean;
  isChangeAmountLinkClicked: boolean;
  residenceCountryMetadata?: ResidenceCountryMetaType;
  runtimeMode: RuntimeMode;
  dropinOrigin?: string;
  payerTheme?: PayerThemeType;
  plan?: PlanResource;
  instalmentTemplate?: InstalmentTemplateResource;
  paylink?: PaylinkResource;
  locale?: Locale;
  // This state is needed to figure if a new bank authorisation is needed.
  // Because we currently always return a bank authorisation id in links,
  // where a bank authorisation exist, this helps us in figuring out when
  // the payer wants to retry the bank authorisation step where the prev
  // bank authorisation is expired
  shouldCreateNewBankAuth?: boolean;
  // This state is needed to figure if the bank-wait was loaded on revisting the flow
  // or from bank connect
  // This helps us in figuring out whether to show the 'waiting from your bank' message
  // immediately or after the timeout in case of IBP in DE and PayTo
  redirectedToBankWaitFromPreviousAction?: boolean;

  mandateMigration?: MandateMigrationResource;

  // This is used for the bank guess by email experiment for UK IBP, which will determine
  // if the institution was selected by the user or guessed and pre-filled by our system.
  isInstitutionGuessed?: boolean;

  // Not being strict with the getter types as they're very straightforward
  initialiseState: Function;
  setAvailableInstitutions: Function;
  setBankAuthorisation: Function;
  setBillingRequest: Function;
  setBillingRequestFlow: Function;
  setBillingRequestId: Function;
  setError: (error?: ErrorType) => void;
  setShowEmailVerification: (showEmailVerification: boolean) => void;
  setFirstTimeLanding: Function;
  setSelectedInstitution: Function;
  setSelectedBankAccountCountryCode: Function;
  setBankDetailsConfirmed: Function;
  setIsEditClicked: Function;
  setIsChangeAmountLinkClicked: Function;
  setResidenceCountryMetadata: (
    residenceCountryMetadata: ResidenceCountryMetaType
  ) => void;
  setRuntimeMode: (runtimeMode: RuntimeMode) => void;
  setDropinOrigin: (origin: string) => void;
  setPayerTheme: (theme: PayerThemeType) => void;
  setPlan: (plan: PlanResource) => void;
  setInstalmentTemplate: (
    instalmentTemplate: InstalmentTemplateResource
  ) => void;
  setPaylink: (paylink: PaylinkResource) => void;
  setRedirectedToBankWaitFromPreviousAction: (
    redirectedToBankWaitFromPreviousAction: boolean
  ) => void;
  setMandateMigration: Function;
  setIsInstitutionGuessed: Function;

  // Routing control. Expose nextjs/router methods in an opinionated format, so
  // we can force people to explain why they're routing between places, and log.
  push: (route: Routes, keyvals: Record<string, unknown>) => void;
  replace: (route: Routes, keyvals: Record<string, unknown>) => void;
  goBack: Function;
  setLocale: Function;
  setShouldCreateNewBankAuth: Function;
  // This is part of the remember me experiment
  setReturningCustomerVerificationAttributes: Function;
  returningCustomerVerificationAttributes?: CustomerEmailVerificationCreateResponseBody;
  showReturningCustomerVerification?: boolean;
  setShowReturningCustomerVerification: Function;
  returningCustomerPersonalDetails?: ReturningCustomerPersonalDetails;
  setReturningCustomerPersonalDetails: Function;
  displayRememberMeOptOut: boolean;
  setDisplayRememberMeOptOut: Function;
}

// Add here any initial state before the app initialisation
const initialState: GlobalStateType = {
  // We're adding getters so we change only one instance of the state.
  // Individual hooks could be used instead but might be more difficult to
  // digest for developers less familiar with React
  initialiseState: () => {},
  setAvailableInstitutions: () => {},
  setBankAuthorisation: () => {},
  setBillingRequest: () => {},
  setBillingRequestFlow: () => {},
  setBillingRequestId: () => {},
  setError: () => {},
  setShowEmailVerification: () => {},
  setReturningCustomerVerificationAttributes: () => {},
  setSelectedInstitution: () => {},
  setSelectedBankAccountCountryCode: () => {},
  setBankDetailsConfirmed: () => {},
  setFirstTimeLanding: () => {},
  setIsEditClicked: () => {},
  setIsChangeAmountLinkClicked: () => {},
  setResidenceCountryMetadata: () => {},
  setRuntimeMode: () => {},
  setDropinOrigin: () => {},
  setPayerTheme: () => {},
  setPlan: () => {},
  setInstalmentTemplate: () => {},
  setPaylink: () => {},
  setRedirectedToBankWaitFromPreviousAction: () => {},
  setMandateMigration: () => {},
  setIsInstitutionGuessed: () => {},

  // Controlflow fields
  isEditClicked: false,
  isChangeAmountLinkClicked: false,
  showEmailVerification: false,
  displayRememberMeOptOut: false,

  // Blank router modifiers, as the router won't be initialised
  push: () => {},
  replace: () => {},
  goBack: () => {},

  // Assume we're hosted, relying on the initialiser to claim otherwise
  runtimeMode: RuntimeMode?.Hosted,
  setLocale: () => {},
  setShouldCreateNewBankAuth: () => {},
  setShowReturningCustomerVerification: () => {},
  setReturningCustomerPersonalDetails: () => {},
  setDisplayRememberMeOptOut: () => {},
};

const GlobalState = createContext(initialState);

interface WithRouterProps {
  router: NextRouter;
  children: React.ReactNode;
}

interface GlobalStateProviderProps extends WithRouterProps {}

type RoutingFunction = ({
  pathname,
  query,
}: {
  pathname: string;
  query: ParsedUrlQuery;
}) => void;

class GlobalStateProvider extends Component<GlobalStateProviderProps> {
  state = initialState;

  initialiseState = (props: {
    billingRequestFlow?: BillingRequestFlowResource;
    showEmailVerification?: Boolean;
    showReturningCustomerVerification?: boolean;
    displayRememberMeOptOut?: boolean;
    origin?: string;
    payerTheme?: PayerThemeType;
  }) => this.setState({ ...this.state, ...props });

  setAvailableInstitutions = (availableInstitutions: InstitutionResource[]) =>
    this.setState({ ...this.state, availableInstitutions });

  setBankAuthorisation = (
    bankAuthorisation: BankAuthorisationResource,
    callback?: () => void
  ) => this.setState({ ...this.state, bankAuthorisation }, callback);

  setBillingRequestId = (billingRequestId: string) =>
    this.setState({ ...this.state, billingRequestId });

  setBillingRequest = (
    billingRequest: BillingRequestResource,
    callback?: () => void
  ) =>
    flushSync(() => {
      this.setState({ ...this.state, billingRequest }, callback);
    });

  setBillingRequestFlow = (billingRequestFlow: BillingRequestFlowResource) =>
    this.setState({ ...this.state, billingRequestFlow });

  setIsEditClicked = (isEditClicked: boolean) =>
    this.setState({ ...this.state, isEditClicked });

  setIsChangeAmountLinkClicked = (isChangeAmountLinkClicked: boolean) =>
    this.setState({ ...this.state, isChangeAmountLinkClicked });

  setShouldCreateNewBankAuth = (
    shouldCreateNewBankAuth: boolean,
    callback?: () => void
  ) => this.setState({ ...this.state, shouldCreateNewBankAuth }, callback);

  setResidenceCountryMetadata = (
    residenceCountryMetadata: ResidenceCountryMetaType
  ) => {
    this.setState({
      ...this.state,
      residenceCountryMetadata,
    });
  };

  setError = (error?: ErrorType) =>
    flushSync(() => {
      this.setState({ ...this.state, error });
    });

  setShowEmailVerification = (showEmailVerification: Boolean) =>
    this.setState({ ...this.state, showEmailVerification });

  setReturningCustomerVerificationAttributes = (
    returningCustomerVerificationAttributes: CustomerEmailVerificationCreateResponseBody
  ) =>
    this.setState({ ...this.state, returningCustomerVerificationAttributes });

  setShowReturningCustomerVerification = (
    showReturningCustomerVerification: boolean
  ) => this.setState({ ...this.state, showReturningCustomerVerification });

  setReturningCustomerPersonalDetails = (returningCustomerPersonalDetails: {
    email: string;
    customer_id: string;
    family_name: string;
    given_name: string;
  }) => this.setState({ ...this.state, returningCustomerPersonalDetails });

  setDisplayRememberMeOptOut = (displayRememberMeOptOut: boolean) =>
    this.setState({ ...this.state, displayRememberMeOptOut });

  setSelectedInstitution = (selectedInstitution: InstitutionResource) =>
    flushSync(() => {
      this.setState({ ...this.state, selectedInstitution });
    });

  setSelectedBankAccountCountryCode = (
    countryCode: CountryCodes,
    callback?: () => void
  ) =>
    this.setState(
      {
        ...this.state,
        selectedBankAccountCountryCode: countryCode,
      },
      callback
    );

  setBankDetailsConfirmed = (
    bankDetailsConfirmed: boolean,
    callback?: () => void
  ) => this.setState({ ...this.state, bankDetailsConfirmed }, callback);

  setRuntimeMode = (runtimeMode: RuntimeMode) =>
    this.setState({ ...this.state, runtimeMode });

  setDropinOrigin = (origin: string) =>
    this.setState({ ...this.state, origin });

  setPayerTheme = (payerTheme: PayerThemeType) => {
    this.setState({ ...this.state, payerTheme });
  };

  setPlan = (plan: PlanResource) => {
    this.setState({ ...this.state, plan });
  };

  setInstalmentTemplate = (instalmentTemplate: InstalmentTemplateResource) => {
    this.setState({ ...this.state, instalmentTemplate });
  };

  setPaylink = (paylink: PaylinkResource) => {
    this.setState({ ...this.state, paylink });
  };

  setRedirectedToBankWaitFromPreviousAction = (
    redirectedToBankWaitFromPreviousAction: boolean
  ) => {
    this.setState({ ...this.state, redirectedToBankWaitFromPreviousAction });
  };

  setMandateMigration = (
    mandateMigration: MandateMigrationResource,
    callback?: () => void
  ) => this.setState({ ...this.state, mandateMigration }, callback);

  setIsInstitutionGuessed = (isInstitutionGuessed: boolean) =>
    this.setState({ ...this.state, isInstitutionGuessed });

  log = Logger("GlobalState");

  push = (route: Routes, keyvals: Record<string, unknown>) => {
    this.route(
      route,
      { operation: "push", ...keyvals },
      this.props.router.push
    );
  };

  replace = (route: Routes, keyvals: Record<string, unknown>) => {
    this.route(
      route,
      { operation: "replace", ...keyvals },
      this.props.router.replace
    );
  };

  setLocale = (locale: Locale | null) =>
    this.setState({ ...this.state, locale });

  // Generic routing function, wrapped by push/replace. Ensures all routing
  // changes are logged consistently.
  route = (
    route: Routes,
    keyvals: Record<string, unknown>,
    func: RoutingFunction
  ) => {
    // The flow route just calculates the preferred and routes there. Improve
    // the routing by calculating our final destination and routing directly
    // there, instead of via /flow.
    if (route === Routes.Flow) {
      // These two are expected to be in the state at this stage.
      if (!this.state.billingRequest) {
        throw new Error("billingRequestFlow not in state");
      }
      if (!this.state.billingRequestFlow) {
        throw new Error("billingRequestFlow not in state");
      }

      route = getPreferred({
        billingRequest: this.state.billingRequest,
        billingRequestFlow: this.state.billingRequestFlow,
        institution: this.state.selectedInstitution,
        bankDetailsConfirmed: this.state.bankDetailsConfirmed,
      });
    }

    this.log({
      message: "changing routes",
      destination_route: route,
      ...keyvals,
    });

    func({
      pathname: route,
      query: {
        ...this.props.router.query,
        // Use the query field of the log object to append parameters to our
        // query string, if we provide one.
        ...(typeof keyvals.query === "object" ? keyvals.query : {}),
      },
    });
  };

  render() {
    return (
      <GlobalState.Provider
        value={{
          ...this.state,
          initialiseState: this.initialiseState,
          setAvailableInstitutions: this.setAvailableInstitutions,
          setBankAuthorisation: this.setBankAuthorisation,
          setBillingRequest: this.setBillingRequest,
          setBillingRequestFlow: this.setBillingRequestFlow,
          setBillingRequestId: this.setBillingRequestId,
          setShowEmailVerification: this.setShowEmailVerification,
          setReturningCustomerVerificationAttributes:
            this.setReturningCustomerVerificationAttributes,
          setShowReturningCustomerVerification:
            this.setShowReturningCustomerVerification,
          setReturningCustomerPersonalDetails:
            this.setReturningCustomerPersonalDetails,
          setDisplayRememberMeOptOut: this.setDisplayRememberMeOptOut,
          setError: this.setError,
          setIsEditClicked: this.setIsEditClicked,
          setIsChangeAmountLinkClicked: this.setIsChangeAmountLinkClicked,
          setResidenceCountryMetadata: this.setResidenceCountryMetadata,
          setSelectedInstitution: this.setSelectedInstitution,
          setSelectedBankAccountCountryCode:
            this.setSelectedBankAccountCountryCode,
          setBankDetailsConfirmed: this.setBankDetailsConfirmed,
          setRuntimeMode: this.setRuntimeMode,
          setDropinOrigin: this.setDropinOrigin,
          setPayerTheme: this.setPayerTheme,
          setPlan: this.setPlan,
          setInstalmentTemplate: this.setInstalmentTemplate,
          setPaylink: this.setPaylink,
          setRedirectedToBankWaitFromPreviousAction:
            this.setRedirectedToBankWaitFromPreviousAction,
          setMandateMigration: this.setMandateMigration,
          setIsInstitutionGuessed: this.setIsInstitutionGuessed,
          // Routing methods
          push: this.push,
          replace: this.replace,
          goBack: () => {
            this.props.router.back();
          },
          setLocale: this.setLocale,
          setShouldCreateNewBankAuth: this.setShouldCreateNewBankAuth,
        }}
      >
        {this.props.children}
      </GlobalState.Provider>
    );
  }
}

// Inject the router as a prop
const GlobalStateProviderRouted = withRouter(GlobalStateProvider);

export { GlobalState, GlobalStateProviderRouted as GlobalStateProvider };
